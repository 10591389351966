<template>
  <div class="row">
    <div class="col">
      <Toast ref="mensajeToast"></Toast>
      <Alerts ref="alert"></Alerts>
      <ModalPDF ref="visorPDF"></ModalPDF>
      <Confirm ref="confirm"></Confirm>
      <ModalVideo ref="modalVideo"></ModalVideo>
      <transition name="slide">
        <div class="card border-warning">
          <div class="card-header">
            <strong>Estudiantes Antiguos</strong>
            <div class=" card-header-actions">
              <form class="form-inline">
                <input id="user" type="hidden" value="<?php echo $user->id ?>"/>
                <b>Gestión: </b>
                <select v-model="gestion" class="form-control mr-2 ml-1"
                        @change="actualizarDatos($event)">
                  <template v-for="(option,key) in gestiones">
                    <optgroup :label="key">
                      <option v-for="suboption in option" :value="suboption.value">
                        {{ suboption.label }}
                      </option>
                    </optgroup>
                  </template>
                </select>

                <b>Filas por p&aacute;gina: </b>
                <select class="form-control mr-2 ml-1" @change="sizeChange($event)"
                        v-model="filasPorPagina">
                  <option v-for="option in sizeoptions" :value="option.value">
                    {{ option.text }}
                  </option>
                </select>

                <button class="btn btn-outline-dark mr-2" type="button"
                        @click="borrarFiltroBtnOnClick()">
                  <font-awesome-icon icon="fa-sort-amount-up-alt"/>
                  Quitar Filtro
                </button>

                <button class="btn btn-outline-primary ml-2" data-html="true"
                        data-toggle="tooltip" title="Inscribir un <b>nuevo</b> estudiante" type="button"
                        @click="nuevoBtnOnClick()">
                  <font-awesome-icon icon="fa-plus"/>
                  Nuevo
                </button>

                <button class="btn btn-outline-success ml-2" data-html="true"
                        data-toggle="tooltip"
                        title="Para exportar todos los datos en un archivo Excel<br/>seleccione la opci&oacute;n <u>Todos</u> en <b>Filas por p&aacute;gina</b>"
                        type="button"
                        @click="excelBtnOnClick()">
                  <font-awesome-icon icon="fa-file-excel"/>
                  Exportar
                </button>

                <CDropdown
                    inNav
                    class="c-header-nav-items"
                    placement="bottom-end"
                    add-menu-classes="pt-0"
                    :caret="false"
                >
                  <template #toggler>
                    <CHeaderNavLink title="Ver videos de ayuda">
                      <font-awesome-icon icon="fas fa-question-circle" size="2x"/>
                    </CHeaderNavLink>
                  </template>
                  <CDropdownItem >
                    <a href="#" @click="$refs.modalVideo.mostrarVideo('video-alumno-regular-inscripción.mp4')"
                       class="text-info" title="Ver video de ayuda">
                      <font-awesome-icon icon="fas fa-video" />
                      Nueva inscripción
                    </a>
                  </CDropdownItem>
                  <CDropdownItem >
                    <a href="#" @click="$refs.modalVideo.mostrarVideo('video-alumno-regular-editar.mp4')"
                       class="text text-success" title="Ver video de ayuda">
                      <font-awesome-icon icon="fas fa-video" />
                      Editar inscripción
                    </a>
                  </CDropdownItem>
                </CDropdown>
              </form>
            </div>
          </div>
          <div class="card-body p-0 pr-1">
            <JqxGrid :width="'100%'" ref="gridSystem"
                     :source="dataAdapter"
                     :autoheight="true" :autorowheight="false" :rowsheight="50"
                     :pageable="true"
                     :pagesize="parseInt(filasPorPagina)"
                     :virtualmode="true"
                     :rendergridrows="rendergridrows"
                     :columns="columns" :enablebrowserselection="true"
                     :enabletooltips="true" :filterable="true"
                     :showfilterrow="true"
                     :sortable="true"
                     :pagermode="'simple'" :localization="localization"
                     :theme="'bootstrap'" :columnsresize="true"/>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import JqxGrid from "jqwidgets-scripts/jqwidgets-vue/vue_jqxgrid";
import jqxInput from "jqwidgets-scripts/jqwidgets-vue/vue_jqxinput";
import Toast from "@/views/notifications/Toast";
import Alerts from "@/views/notifications/Alerts";
import ModalPDF from "@/views/notifications/ModalPDF";
import Confirm from "@/views/notifications/Confirm";
import ModalVideo from "@/views/notifications/ModalVideo";

var sourceLst;
var address;
var modalPdf;
var pagesizeoptions = [6, 10, 50, 100, 500, 1000, 5000, 10000];
var pagesize = pagesizeoptions[0];

export default {
  name: 'Alumnos',
  components: {
    ModalVideo,
    Confirm,
    ModalPDF,
    Alerts,
    Toast,
    JqxGrid,
    jqxInput
  },
  data: () => {
    return {
      darkModal: false,
      items: [],
      currentPage: 1,
      perPage: 7,
      totalRows: 0,
      filasPorPagina: pagesizeoptions[0],
      sizeoptions: [],
      sedeSeleccionada: localStorage.sedeSeleccionada,
      gestiones: [],
      gestion: 0,
      dataAdapter: new jqx.dataAdapter(sourceLst, {
        loadError: function (xhr, status, error) {
          console.log("Error ocurrido al obtener los datos del servidor, " + status + ", " + error);
          location.href = location.protocol + "//" + location.host + "/#/login";
        }
      }),
      rendergridrows: (params) => {
        return params.data;
      },
      columns: [
        {
          text: 'Código Alumno', datafield: "codigo_alumno", width: '6%', cellsalign: 'center',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Código<br />Alumno</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Código Alumno"});
          }
        },
        {
          text: 'Apellido1', datafield: "apellido1", width: '10%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Primer<br />apellido</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Apellido1"});
          }
        },
        {
          text: 'Apellido2', datafield: "apellido2", width: '10%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Segundo<br />apellido</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Apellido2"});
          }
        },
        {
          text: 'Nombres', datafield: "nombres", width: '12%',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Nombres"});
          }
        },
        {
          text: 'Carrera', datafield: "carrera", width: '19%',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Carrera"});
          }
        },
        {
          text: '', width: '5%', cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return '<a class="btn btn-block btn-primary ml-1 mt-1 pl-0 pr-0" href="/#/inscripcion/' + rowdata.id + '/' + rowdata.codigo_alumno + '/editarantiguo" title="Editar inscripcion de alumno nuevo">Editar</a>';
          }, filterable: false, sortable: false
        },
        {
          text: '',
          datafield: 'documentos',width: '9%', cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return '<a class="btn btn-block btn-success ml-1 mt-1 pl-0 pr-0" href="/#/inscripcion/' + rowdata.codigo_alumno + '/documentos" title="Recibir Documentos (Hoja de ruta)">Documentos</a>';
          }, filterable: false, sortable: false
        },
        {
          text: '',
          datafield: 'compromisos', width: '9%', cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return '<a class="btn btn-block btn-secondary ml-1 mt-1 pl-0 pr-0" href="/#/inscripcion/' + rowdata.id + '/' + rowdata.codigo_alumno + '/compromisos" title="Compromisos para entrega de Documentos">Compromisos</a>';
          }, filterable: false, sortable: false
        },
        {
          text: '',
          datafield: 'contratos',width: '7%', cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return '<a class="btn btn-block btn-warning ml-1 mt-1 pl-0 pr-0" href="/#/inscripcion/' + rowdata.id + '/' + rowdata.codigo_alumno + '/' + rowdata.gestion + '/contrato" title="Imprimir contrato">Contrato</a>';
          }, filterable: false, sortable: false
        },
        {
          text: "",datafield: 'boleta', width: '7%', columntype: "button",
          filterable: false, sortable: false,hidden:true,
          cellclassname: "btn-orange",
          renderer: function () {
            return '';
          },
          cellsrenderer: function () {
            return "Boleta";
          },
          buttonclick: function (row, event) {
            let button = $(event.currentTarget);
            let grid = button.parents("[id^=jqxGrid]");
            let rowData = grid.jqxGrid('getrowdata', row);
            modalPdf.$refs.alert.show('Descargando documento');
            axios.get(
                address + '/api/inscripcion/' + rowData.id + '/boletapdf?token=' + localStorage.getItem("api_token"),
                {responseType: 'blob'}
            )
                .then((response) => {
                  modalPdf.$refs.alert.hide();
                  modalPdf.$refs.visorPDF.mostrarDocumento(response.data);
                })
                .catch(function (error) {
                  modalPdf.$refs.alert.hide();
                  modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
                });
          }
        },
        {
          text: "",datafield: 'hojaruta', width: '6%', columntype: "button",
          filterable: false, sortable: false,
          cellclassname: "btn-green",
          renderer: function () {
            return '';
          },
          cellsrenderer: function () {
            return "Hoja Ruta";
          },
          buttonclick: function (row, event) {
            let button = $(event.currentTarget);
            let grid = button.parents("[id^=jqxGrid]");
            let rowData = grid.jqxGrid('getrowdata', row);
            modalPdf.$refs.alert.show('Descargando documento');
            axios.get(
                address + '/api/inscripcion/' + rowData.id + '/hojarutapdf?token=' + localStorage.getItem("api_token"),
                {responseType: 'blob'}
            )
                .then((response) => {
                  modalPdf.$refs.alert.hide();
                  modalPdf.$refs.visorPDF.mostrarDocumento(response.data);
                })
                .catch(function (error) {
                  modalPdf.$refs.alert.hide();
                  modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
                });
          }
        },
        {
          text: "", datafield: 'eliminar', width: '7%', columntype: "button",
          filterable: false, sortable: false,
          cellclassname: "btn-red",
          renderer: function () {
            return '';
          },
          cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return 'Eliminar';
          },
          buttonclick: function (row, event) {
            let button = $(event.currentTarget);
            let grid = button.parents("[id^=jqxGrid]");
            let rowData = grid.jqxGrid('getrowdata', row);
            if (rowData.id > 0) {

            }
            modalPdf.$refs.confirm.confirm('¿Esta sugur@ de eliminar el registro?','',function () {
              modalPdf.$refs.alert.show('Eliminando registro');
              axios.get(
                  address + '/api/inscripcion/' + rowData.id + '/eliminarinscripcionantiguo?token=' + localStorage.getItem("api_token")
              )
                  .then((response) => {
                    modalPdf.$refs.alert.hide();
                    modalPdf.$refs.gridSystem.updatebounddata();
                  })
                  .catch(function (error) {
                    modalPdf.$refs.alert.hide();
                    modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
                  });
            })
          }
        },
      ],
      localization: {
        'emptydatastring': "No existen datos para esta SEDE",
      },
    }
  },
  beforeCreate: function () {
    const url = '';
    address = this.$apiAdress;
    sourceLst = {
      url: url,
      datafields: [
        {name: 'id', type: "int"},
        {name: 'codigo_alumno', type: "string"},
        {name: 'apellido1', type: "string"},
        {name: 'apellido2', type: "string"},
        {name: 'nombres', type: "string"},
        {name: 'carrera', type: "string"},
        {name: 'gestion', type: "string"},
        {name: 'estado', type: "string"},
        {name: 'documentos', type: "string"},
        {name: 'compromisos', type: "string"},
        {name: 'contratos', type: "string"},
        {name: 'boleta', type: "string"},
        {name: 'hojaruta', type: "string"},
        {name: 'editar', type: "string"},
        {name: 'eliminar', type: "string"},
      ],
      data: {
        sede: this.sede
      },
      datatype: "json",
      root: "Rows",
      id: 'codigo_alumno',
      sortcolumn: 'codigo_alumno',
      sortdirection: 'desc',
      cache: false,
      beforeprocessing: (data) => {
        if (data != null) {
          sourceLst.totalrecords = data[0].TotalRows;
          let i = 0;
          this.sizeoptions = [];
          while (i < 8 && pagesizeoptions[i] < data[0].TotalRows) {
            this.sizeoptions[i] = {text: pagesizeoptions[i], value: pagesizeoptions[i]};
            i++;
          }
          if (i > 1) {
            this.sizeoptions[i] = {text: "TODOS", value: data[0].TotalRows};
          }
        }
        modalPdf.$refs.alert.hide();
      },
      filter: () => {
        this.$refs.gridSystem.updatebounddata("filter");
      },
      sort: () => {
        this.$refs.gridSystem.updatebounddata("sort");
      },
    };
  },
  methods: {
    eliminarUser(id) {
      let self = this;
      let userId = id;
      axios.post(this.$apiAdress + '/api/alumnos/' + id + '?token=' + localStorage.getItem("api_token"), {
        _method: 'DELETE'
      })
          .then(function (response) {
            modalPdf.$refs.mensajeToast.makeToast('', 'Successfully eliminard user.', 'danger');
            self.getAlumnos();
          })
          .catch(function (error) {
            console.log(error);
            self.$router.push({path: '/login'});
          });
    },
    excelBtnOnClick: function () {
      this.$refs.gridSystem.hidecolumn('documentos');
      this.$refs.gridSystem.hidecolumn('editar');
      this.$refs.gridSystem.hidecolumn('eliminar');
      let date = new Date();
      let dateStr =
          date.getFullYear() + "" +
          ("00" + (date.getMonth() + 1)).slice(-2) + "" +
          ("00" + date.getDate()).slice(-2) + "_" +
          ("00" + date.getHours()).slice(-2) + "" +
          ("00" + date.getMinutes()).slice(-2) + "" +
          ("00" + date.getSeconds()).slice(-2);
      this.$refs.gridSystem.exportdata('xls', 'Inscritos' + dateStr, true, null, true, modalPdf.$apiAdress + "/api/reporte/exportarxls/" + '?token=' + localStorage.getItem("api_token"));

      this.$refs.gridSystem.showcolumn('documentos');
      this.$refs.gridSystem.showcolumn('editar');
      this.$refs.gridSystem.showcolumn('eliminar');
    },
    nuevoBtnOnClick: function () {
      this.$router.push({path: '/inscripcion/0/0/editarantiguo'});
    },
    borrarFiltroBtnOnClick: function () {
      this.$refs.gridSystem.clearfilters();
    },
    sizeChange: function (event) {
      try {
        modalPdf.$refs.alert.show("Actualizando datos");
        this.$refs.gridSystem.pagesize = parseInt(this.filasPorPagina);
        this.$refs.gridSystem.updatebounddata();
      } catch (e) {}
    },
    actualizarDatos: function (event) {
      sourceLst.url = this.$apiAdress + '/api/inscripcion/' + this.gestion + '/' + localStorage.sedeSeleccionada + '/antiguos?token=' + localStorage.getItem("api_token");
      this.$refs.gridSystem.clearfilters();
      //this.$refs.gridSystem.updatebounddata();
      if(this.gestion.includes("VERANO") || this.gestion.includes("INVIERNO")){
        this.$refs.gridSystem.hidecolumn('contratos');
        this.$refs.gridSystem.showcolumn('boleta')
      }else{
        this.$refs.gridSystem.showcolumn('contratos');
        this.$refs.gridSystem.hidecolumn('boleta')
      }
    }
  },
  updated() {
    if (sourceLst.url === '') {
      this.actualizarDatos();
    }
  },
  mounted() {
    modalPdf = this;
    this.$root.$on('sedeSeleccionada', data => {
      left.sede = data;
    });
    modalPdf.$refs.alert.showHideColumn('documentos',modalPdf.$refs.gridSystem)
    modalPdf.$refs.alert.showHideColumn('compromisos',modalPdf.$refs.gridSystem);
    modalPdf.$refs.alert.showHideColumn('contratos',modalPdf.$refs.gridSystem);
    modalPdf.$refs.alert.showHideColumn('hojaruta',modalPdf.$refs.gridSystem);
    axios.post(this.$apiAdress + '/api/factura/' + localStorage.sedeSeleccionada + '/opcionesfactura?token=' + localStorage.getItem("api_token"),
        {'tipo_lista':'inscripcion_lista'}
    )
        .then(function (response) {
          let valorPorDefecto = [{value: '', label: ':: SELECCIONAR ::'}];
          modalPdf.gestiones = response.data.gestiones;
          modalPdf.gestion = response.data.mesActual;
        }).catch(function (error) {
      console.log(error);
      modalPdf.$router.push({path: '/login'});
    });
  },
}
</script>